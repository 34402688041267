/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
/// /// EXTERNAL /// ///
// mui //
import { Skeleton } from '@mui/material';

const EnhancedSkeleton = ({ loader, children }) => {
  if (!loader) {
    return (
      <Skeleton
        sx={{ bgcolor: 'grey.300' }}
        animation="wave"
        variant="rectangular"
        height="100vh"
        width="100%"
      />
    );
  }
  return children;
};

export default EnhancedSkeleton;
