/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import { useContext, useEffect, useMemo } from 'react';
/// /// EXTERNAL /// ///
// context //
import { ChannelParametersContext } from '@prosperia/context.channel-parameters-context';
// third party libraries //
import { useParams } from 'react-router-dom';
// services //
import { channelParametersApi } from 'services/commonService';

const useChannelParameters = () => {
  const { channelID } = useParams();

  const {
    channelParameters,
    setChannelParameters,
    setLoaderChannelParameters,
  } = useContext(ChannelParametersContext);

  const parameters = useMemo(() => (
    channelParameters
  ), [channelParameters]);

  useEffect(() => {
    let isSubscribed = true;
    const withoutParams = !parameters || Object.keys(parameters).length === 0;
    if (channelID && withoutParams) {
      const getChannel = async () => {
        await channelParametersApi(channelID)
          .then((info) => {
            if (isSubscribed) {
              const result = info.data;
              setChannelParameters(result.data);
              setLoaderChannelParameters(true);
            }
          })
          .catch(() => {
            setLoaderChannelParameters(false);
          });
      };
      getChannel();
    }
    return () => {
      isSubscribed = false;
    };
  }, [channelID, parameters, setChannelParameters, setLoaderChannelParameters]);

  return {};
};

export default useChannelParameters;
