/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import React from 'react';
/// /// EXTERNAL /// ///
// third party libraries //
import { Routes, Route } from 'react-router-dom';
/// /// INTERNAL /// ///
// components //
import ReportProvider from 'components/provider/ReportProvider';

const App = () => (
  <Routes>
    <Route exact path="/:channelID/:POC/:interaction_id" element={<ReportProvider />} />
  </Routes>
);

export default App;
