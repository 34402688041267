/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
/// /// EXTERNAL /// ///
// third party libraries //
import axios from 'axios';

export const channelParametersApi = async (channelID) => {
  const api = process.env.REACT_APP_API_GET_CHANNELS;
  const url = `${api}?channel=${channelID}`;
  return axios.get(url);
};

export const pocParametersApi = async (application, channelID, POC) => {
  const api = process.env.REACT_APP_API_GET_POCS;
  const url = `${api}?interactionApp=${application}&channel=${channelID}&poc=${POC}`;
  return axios.get(url);
};
