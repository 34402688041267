/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import React, { useContext } from 'react';
// // EXTERNAL // //
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// // INTERNAL // //
// context //
import { ErrorContext } from 'context/ErrorContext';

const Alert = React.forwardRef(({ onClose, severity, children }, ref) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant="filled"
    sx={{ width: '100%' }}
    onClose={onClose}
    severity={severity}
  >
    {children}

  </MuiAlert>
));

const EnhancedSnackbar = () => {
  const {
    message, setMessage,
  } = useContext(ErrorContext);

  const {
    open, severity, text, duration,
  } = message;

  const handleClose = () => {
    setMessage({
      ...message,
      open: false,
    });
  };

  return (
    <Stack spacing={2}>
      <Snackbar
        open={open}
        autoHideDuration={duration || 6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
        sx={{ maxWidth: '45%' }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default EnhancedSnackbar;
