/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import {
  createContext, useEffect, useState, useMemo,
} from 'react';
// // EXTERNAL // //
// third party libraries //
import TagManager from 'react-gtm-module';
import { useParams } from 'react-router-dom';
// services //
import { reportApi } from 'services/reportService';

export const InteractionContext = createContext();

export const InteractionProvider = ({ children }) => {
  const GTM_ID_RETINIA = 'GTM-N7C6D27';

  const { channelID, POC } = useParams();

  const [interaction, setInteraction] = useState({});
  const [interactionLoaded, setInteractionLoaded] = useState(false);
  const [pdfData, setPdfData] = useState({
    logoBase64: '',
    imagesBase64: null,
  });

  const { interaction_id: interactionId } = useParams();

  const {
    poc_info: pocInfo,
    pacient_info: patient,
    interaction_parameters: interactionParameters,
    report,
  } = interaction;

  useEffect(() => {
    const isProduction = process.env.NODE_ENV === 'production';
    if (isProduction) {
      TagManager.initialize({ gtmId: GTM_ID_RETINIA });
    }
  }, []);

  useEffect(() => {
    const getInteraction = async () => {
      await reportApi(interactionId, channelID, POC).then((info) => {
        const result = info.data;
        let data;
        if (result.output) data = result.output;
        else data = result.data;
        setInteraction(data);
        setInteractionLoaded(true);
      })
        .catch((error) => {
          console.log('Unexpected Error get Reports', error);
          const { status } = error.response;
          if (status === 301) {
            window.location.assign(
              `${process.env.REACT_APP_CALCS_REPORT}/${channelID}/${POC}/${interactionId}`,
            );
          }
        });
    };
    getInteraction();
  }, [channelID, POC, interactionId]);

  const context = useMemo(
    () => ({
      pdfData,
      pocInfo,
      patient,
      interactionParameters,
      report,
      interactionLoaded,
      setPdfData,
    }),
    [
      pdfData,
      pocInfo,
      patient,
      interactionParameters,
      report,
      interactionLoaded,
      setPdfData],
  );

  return (
    <InteractionContext.Provider
      value={context}
    >
      {children}
    </InteractionContext.Provider>
  );
};
