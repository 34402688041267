/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
/// /// EXTERNAL /// ///
// third party libraries //
import axios from 'axios';

const api = process.env.REACT_APP_API_GET_REPORT;

export const reportApi = async (interactionId, channelID, POC) => {
  const url = `${api}/${interactionId}?channel=${channelID}&poc=${POC}`;
  return axios.get(url, {
    crossdomain: true,
  });
};

export const reportLogoBase64Api = async (payload) => {
  const url = `${api}/channel/logo`;
  return axios.post(url, {
    data: payload,
  }, {
    crossdomain: true,
  });
};

export const reportImagesBase64Api = async (interactionId) => {
  const url = `${api}/${interactionId}/image`;
  return axios.get(url, {
    crossdomain: true,
  });
};
