/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import React, { useContext } from 'react';
/// /// EXTERNAL /// ///
// mui //
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
// context //
import { ChannelParametersProvider } from '@prosperia/context.channel-parameters-context';
import { PatientProvider } from '@prosperia/context.patient-context';
import { PocParametersProvider } from '@prosperia/context.poc-parameters-context';
import { ChannelParametersContext } from '@prosperia/context.channel-parameters-context';
import { PocParametersContext } from '@prosperia/context.poc-parameters-context';
// third party libraries //
import { useParams } from 'react-router-dom';
/// /// INTERNAL /// ///
// components //
import Report from 'components/pages/Report';
import EnhancedSkeleton from 'components/atoms/common/EnhancedSkeleton';
// context //
import { InteractionProvider } from 'context/InteractionContext';
import { InteractionContext } from 'context/InteractionContext';
// hook //
import useTheme from 'hook/useTheme';
import useChannelParameters from 'hook/useChannelParameters';
import usePocParameters from 'hook/usePocParameters';

const Theme = () => {
  const { POC } = useParams();
  const { interactionLoaded } = useContext(InteractionContext);
  const { isLoaderChannelParameters } = useContext(ChannelParametersContext);
  const { isLoaderPocParameters } = useContext(PocParametersContext);
  const { lightTheme } = useTheme();
  useChannelParameters();
  usePocParameters();

  let loader = interactionLoaded;
  if (loader) {
    loader = POC ? isLoaderPocParameters : isLoaderChannelParameters;
  }
  return (
    <EnhancedSkeleton loader={loader}>
      <PatientProvider>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Report />
        </ThemeProvider>
      </PatientProvider>
    </EnhancedSkeleton>
  );
};

const ReportProvider = () => (
  <ChannelParametersProvider>
    <PocParametersProvider>
      <InteractionProvider>
        <Theme />
      </InteractionProvider>
    </PocParametersProvider>
  </ChannelParametersProvider>
);

export default ReportProvider;
