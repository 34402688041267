/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import { useContext, useEffect, useMemo } from 'react';
/// /// EXTERNAL /// ///
// context //
import { PocParametersContext } from '@prosperia/context.poc-parameters-context';
// third party libraries //
import { useParams } from 'react-router-dom';
// services //
import { pocParametersApi } from 'services/commonService';

const usePocParameters = () => {
  const { channelID, POC } = useParams();

  const {
    pocParameters,
    setPocParameters,
    setLoaderPocParameters,
  } = useContext(PocParametersContext);

  const parameters = useMemo(() => pocParameters, [pocParameters]);

  useEffect(() => {
    let isSubscribed = true;
    const withoutParams = !parameters || Object.keys(parameters).length === 0;
    if (channelID && POC && withoutParams) {
      const getPOCParemeters = async () => {
        // TODO: remove app
        await pocParametersApi('retinia', channelID, POC)
          .then((info) => {
            if (isSubscribed) {
              const result = info.data;
              setPocParameters(result.data);
              setLoaderPocParameters(true);
            }
          })
          .catch(() => {
            setLoaderPocParameters(false);
          });
      };
      getPOCParemeters();
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    channelID,
    POC,
    parameters,
    setLoaderPocParameters,
    setPocParameters,
  ]);

  return {};
};

export default usePocParameters;
