/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import React from 'react';
import ReactDOM from 'react-dom';
/// /// EXTERNAL /// ///
// third party libraries //
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/plus-jakarta-sans/200.css';
import '@fontsource/plus-jakarta-sans/300.css';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/800.css';
/// /// INTERNAL /// ///
// components //
import EnhancedSnackbar from 'components/atoms/common/EnhancedSnackbar';
import Interceptors from 'components/atoms/common/Interceptors';
// context //
import { ErrorProvider } from 'context/ErrorContext';
// components //
import App from './App';
// utils //
import * as serviceWorker from './serviceWorker';
import './index.css';

const publicUrl = process.env.PUBLIC_URL;
ReactDOM.render(
  <BrowserRouter basename={publicUrl}>
    <ErrorProvider>
      <EnhancedSnackbar />
      <Interceptors />
      <App />
    </ErrorProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

/**
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
