/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import { useContext } from 'react';
/// /// EXTERNAL /// ///
// mui //
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
// context //
import { ChannelParametersContext } from '@prosperia/context.channel-parameters-context';

const useTheme = () => {
  const { channelParameters } = useContext(ChannelParametersContext);
  const { primaryColor, secondaryColor, logoHeader } = channelParameters;

  const lightTheme = createTheme({
    downSm: {
      navBar: '48px',
      backDrop: '53px',
      gapMain: '85px',
    },
    upSm: {
      navBar: '64px',
      backDrop: '58px',
      gapMain: '102px',
    },
    custom: {
      color: '#FFFFFF',
      minWidth: 'min-width:1440px',
      maxHeight: 'max-height:600px',
    },
    channel: {
      logo: logoHeader,
    },
    palette: {
      mode: 'light',
      primary: {
        main: primaryColor || '#CFCFCF',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: secondaryColor || '#CFCFCF',
      },
      error: {
        main: '#FF5252',
      },
      background: {
        default: '#f5f5f5',
      },
      text: {
        primary: grey[800],
      },
    },
    typography: {
      fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
    },
    zIndex: {
      modal: 1500,
    },
  });

  const darkTheme = createTheme({
    downSm: {
      navBar: '48px',
      backDrop: '53px',
      gapMain: '85px',
    },
    upSm: {
      navBar: '64px',
      backDrop: '58px',
      gapMain: '102px',
    },
    custom: {
      color: 'rgba(0, 0, 0, 1)',
    },
    palette: {
      mode: 'dark',
      primary: {
        main: primaryColor || '#CFCFCF',
        contrastText: '#FFFFFF',
      },
      background: {
        paper: 'rgba(0, 0, 0, 1)',
      },
    },
    typography: {
      fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
    },
  });
  return {
    lightTheme,
    darkTheme,
  };
};

export default useTheme;
